<template>
  <Teleport to="body">
    <HocModalBox
      v-if="modelValue"
      :can-close="true"
      class="fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
    >

      <!-- Main modal -->
      <div
        aria-hidden="true"
        class="fixed top-0 left-0 right-0 z-50 w-full p-4 md:inset-0 h-[calc(100%-1rem)] md:h-full flex flex-col justify-center items-center"
        data-modal-backdrop="static"
        tabindex="-1"
      >
   
        <div :class="getClass(modelWidth)" class="relative w-full h-screen md:h-auto">
          <!-- Modal content -->
          <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <!-- Modal header -->
            <div
              v-if="modelTitle"
              :class="headerColor"
              class="flex items-start justify-between px-4 py-2 border-b rounded-t dark:border-gray-600"
            >
              <h3 class="text-md 2xl:text-xl font-semibold text-gray-900 dark:text-white">
                {{ modelTitle }}
              </h3>
              <button
                v-if="modelClose"
                class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="staticModal"
                type="button"
                @click.prevent="isModelVisible"
              >
                <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path
                    clip-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    fill-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <!-- Modal body -->
            <slot />
            <slot name="modelFooter"></slot>
          </div>
        </div>
      </div>
    </HocModalBox>
    <div v-if="modelValue" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </Teleport>
</template>
<script setup>
const emit = defineEmits(['update:modelValue','onClosePopup']);
defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  modelTitle: {
    type: String,
    default: null
  },
  modelWidth: {
    type: String,
    default: 'max-w-2xl'
  },
  modelClose: {
    type: Boolean,
    default: true
  },
  headerColor: {
    type: String,
    default: ''
  }
});
const isModelVisible = () => {
  emit('update:modelValue', false);
  emit('onClosePopup');
};
const getClass = (className) => className;
</script>
